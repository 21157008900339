/* eslint-disable @typescript-eslint/naming-convention */
import { enumToArray } from '@wndr/common/core/utils/enum-to-array';
import { z } from 'zod';

/** Credential type schema. */
export const credentialTypeSchema = z.enum([
	'board_certification',
	'state_medical_license',
	'curriculum_vitae',
	'dea_certificate',
	'interstate_medical_licensure_compact',
	'insurance',
]);

/** Credential type. */
export type CredentialType = z.infer<typeof credentialTypeSchema>;

export namespace CredentialType {

	const MAP_TO_READABLE: Record<CredentialType, string> = {
		board_certification: 'Board Certification',
		state_medical_license: 'State Medical License',
		curriculum_vitae: 'Curriculum Vitae',
		dea_certificate: 'DEA Certificate',
		interstate_medical_licensure_compact: 'Interstate Medical Licensure Compact',
		insurance: 'Insurance',
	};

	/** Available credential types. */
	export const ENUM = credentialTypeSchema.Enum;

	/**
	 * Map credential type to human-readable.
	 * @param type Type.
	 */
	export function toReadable(type: CredentialType): string {
		return MAP_TO_READABLE[type];
	}

	/** Get array of available credential types. */
	export function toArray(): CredentialType[] {
		return enumToArray(ENUM);
	}

	/** Get credentials count. */
	export function getCount(): number {
		return enumToArray(ENUM).length;
	}
}
