export namespace AppUtils {

	/**
	 * Pads a number with a leading zero.
	 * @param num The number to pad.
	 * @param maxLength Maximum length.
	 * @returns A string representation of the padded number.
	 */
	export function zeroPad(num: number, maxLength: number): string {
		return num.toString().padStart(maxLength, '0');
	}

	/**
	 * Helper to create an iterator of a range from 0 to `num`.
	 * @param num Number to iterate over.
	 */
	export function iterateNumber(num: number): Iterable<unknown> {
		return Array(num).fill(null);
	}
}
