import { Injectable } from '@angular/core';
import { z } from 'zod';

import { DateUtils } from '../utils/date-utils';

import { Mapper } from './mappers';

/** Date mapper. */
@Injectable({ providedIn: 'root' })
export class DateMapper implements Mapper<string, Date> {

	/** @inheritdoc */
	public fromDto(data: string): Date {
		return z.date().parse(new Date(data));
	}

	/** @inheritdoc */
	public toDto(data: Date): string {
		return data.toISOString();
	}

	/**
	 * Map date object to date only string in format 'YYYY-MM-DD'.
	 * @param date Date.
	 */
	public toDateOnlyDto(date: Date): string {
		return DateUtils.toDateOnlyString(date);
	}
}
