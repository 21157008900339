import { z } from 'zod';

import { userRoleSchema } from '../models/user-role';

import { educationDtoSchema } from './education.dto';
import { trainingDtoSchema } from './training.dto';
import { credentialDtoSchema } from './credentials/credential.dto';
import { twoFactorMethodSchema } from './two-factor/two-factor-method.dto';

/** User DTO schema. */
export const userDtoSchema = z.object({
	id: z.number(),
	avatar: z.string().nullable(),
	background_image: z.string().nullable(),
	first_name: z.string(),
	last_name: z.string(),
	preferred_name: z.string().optional(),
	initials: z.string().optional(),
	credentials: z.string(),
	username: z.string(),
	entity: z.string().optional(),
	pronoun: z.string().optional(),
	clinician_type: z.string(),
	graduation_date: z.string()
		.nullable()
		.optional(),
	email: z.string(),
	secondary_email: z.string().optional(),
	phone_number: z.string(),
	primary_region_practice_state: z.string(),
	primary_region_practice_zip: z.string(),
	address: z.string().optional(),
	address_state: z.string(),
	address_zip: z.string(),
	fax_number: z.string().optional(),
	description: z.string().optional(),
	specialty: z.array(z.string()),
	specialty_area: z.string().optional(),
	has_contact: z.boolean(),
	total_contacts: z.number(),
	created: z.string(),
	educations: z.array(educationDtoSchema),
	trainings: z.array(trainingDtoSchema),
	documents: z.array(credentialDtoSchema),
	npi_number: z.string(),
	two_factor_type: twoFactorMethodSchema.or(z.literal('')).optional(),
	role: userRoleSchema,
	allow_notifications: z.boolean().optional(),
	average_rating: z.number().nullish(),
	number_of_rating_consultations: z.number().nullish(),
	available_for_page: z.boolean().optional(),
	baa_file: z.string().nullable(),
});

/** User DTO. */
export type UserDto = Readonly<z.infer<typeof userDtoSchema>>;

/** User update data DTO. */
export type UserUpdateDataDto = Partial<Pick<
UserDto,
| 'avatar'
| 'first_name'
| 'last_name'
| 'preferred_name'
| 'initials'
| 'credentials'
| 'username'
| 'entity'
| 'pronoun'
| 'clinician_type'
| 'graduation_date'
| 'address'
| 'address_state'
| 'primary_region_practice_state'
| 'primary_region_practice_zip'
| 'fax_number'
| 'description'
| 'specialty'
| 'specialty_area'
| 'background_image'
| 'npi_number'
| 'role'
| 'allow_notifications'
| 'available_for_page'
>>;
