import { z } from 'zod';

/** Education file DTO schema. */
export const educationFileDtoSchema = z.object({

	/** ID. */
	id: z.number(),

	/** File URL. */
	file: z.string(),

	/** Name. */
	name: z.string(),
});

/** Education file DTO. */
export type EducationFileDto = z.infer<typeof educationFileDtoSchema>;

/** Education file create/update DTO schema. */
export const educationFileCreateUpdateDtoSchema = educationFileDtoSchema.pick({
	file: true,
	name: true,
});

/** Education file create/update DTO. */
export type EducationFileCreateUpdateDto = z.infer<typeof educationFileCreateUpdateDtoSchema>;
