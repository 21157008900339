import { z } from 'zod';

/** Training DTO schema. */
export const trainingDtoSchema = z.object({

	/** ID. */
	id: z.number(),

	/** Training program. */
	program: z.string(),

	/** School. */
	school: z.string(),

	/** Hospital system. */
	hospital_system: z.string(),

	/** Specialty. */
	specialty: z.string(),

	/** Date. */
	date: z.string(),
});

/** Training DTO. */
export type TrainingDto = Readonly<z.infer<typeof trainingDtoSchema>>;

/** Training create/update DTO. */
export type TrainingCreateUpdateDto = Readonly<Pick<TrainingDto, 'program' | 'school' | 'hospital_system' | 'specialty' | 'date'>>;
