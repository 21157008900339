import { Injectable, inject } from '@angular/core';

import { User } from '../models/user';
import { Session } from '../models/session';
import { Education } from '../models/education';
import { Training } from '../models/training';
import { Credential } from '../models/credentials/credential';
import { Notification } from '../models/notifications/notification';

import { AppConfig } from './app.config';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {

	private readonly appConfigService = inject(AppConfig);

	/** Auth-related routes. */
	public readonly auth = {
		register: this.toApi('auth/register/'),
		login: this.toApi('auth/login/'),
		resetPassword: this.toApi('auth/password-reset/'),
		refreshSecret: this.toApi('auth/token/refresh/'),
		confirmPasswordReset: this.toApi('auth/password-reset-confirm/'),
	} as const;

	/** Endpoints for two-factor auth. */
	public readonly twoFactorAuth = {
		getOtpCode: this.toApi('auth/two-factor-auth/get-otp-code/'),
		login: this.toApi('auth/two-factor-auth/login-second-part/'),
		changeMethod: this.toApi('users/profile/change-2fa-type/'),
	};

	/** Routes for getting/editing current user's info. */
	public readonly user = {
		currentProfile: this.toApi('users/profile/'),
		privacySettings: this.toApi('users/profile/privacy-settings/'),
		changePassword: this.toApi('users/change_password/'),
		getUserById: (id: User['id']) => this.toApi('users', `${id}/`),
		createConnectedAccount: this.toApi('users/profile/create-connected-account/'),
		deletionCode: this.toApi('users/delete-account/deletion-code/'),
		confirmDeletion: this.toApi('users/delete-account/confirm/'),
		dashboard: this.toApi('users/profile/dashboard/'),
		getConnectedAccount: this.toApi('users/profile/get-connected-account/'),
		sendEmailConfirmationCode: this.toApi('users/confirm-email/email-confirmation-code/'),
		sendSecondaryEmailConfirmationCode: this.toApi('users/confirm-secondary-email/email-confirmation-code/'),
		confirmEmail: this.toApi('users/confirm-email/confirm/'),
		confirmSecondaryEmail: this.toApi('users/confirm-secondary-email/confirm/'),
		updateEmail: this.toApi('users/profile/update-email/'),
		updateSecondaryEmail: this.toApi('users/profile/update-secondary-email/'),
	} as const;

	/** Routes for HIPAA compliance. */
	public readonly hipaa = {
		hipaaCompliance: this.toApi('users/hipaa-compliance/'),
		markHipaaComplianceAsRead: this.toApi('users/profile/read-hipaa-compliance/'),
	};

	/** Routes for BAA. */
	public readonly baa = {
		renderHTML: this.toApi('users/render-baa-template/'),
	};

	/** Routes for help requests. */
	public readonly helpRequests = {
		base: this.toApi('help-requests/'),
	};

	/** URLs of external storages. */
	public readonly storage = {
		getParams: this.toApi('s3/get_upload_params/'),
	} as const;

	/** Routes for users' info. */
	public readonly users = {
		get: this.toApi('users/'),
		getUserById: (id: User['id']) => this.toApi('users', `${id}/`),
		contact: this.toApi('users/contact/'),
		removeContact: (id: User['id']) => this.toApi('users/contact', `${id}/`),
		availableForPager: this.toApi('users/available-for-page/'),
	} as const;

	/** URLs that provides constant values. */
	public readonly constants = {
		clinicianTypes: this.toApi('constants/clinician-choices'),
		specialties: this.toApi('constants/specialty-choices'),
		states: this.toApi('constants/state-choices'),
		sessionTypes: this.toApi('constants/session-types'),
		educationPrograms: this.toApi('constants/education-programs'),
		educationMajors: this.toApi('constants/education-majors'),
		trainingPrograms: this.toApi('constants/training-programs'),
		pronouns: this.toApi('constants/pronounce-choices'),
		documentStatuses: this.toApi('constants/document-statuses'),
		certificationBoardGroup: this.toApi('constants/certification-board-group'),
		certificationBoardStatuses: this.toApi('constants/board-certification-status-choices'),
		starRatings: this.toApi('constants/star-rating-choices'),
		yearsOfExperience: this.toApi('constants/year-of-experience-choices'),
		numberOfConsultations: this.toApi('/api/v1/constants/number-of-consultations-choices'),
		pageSessionTypes: this.toApi('constants/page-session-type-choices'),
		pageDurationChoices: this.toApi('constants/page-duration-choices'),
	} as const;

	/** URLs for consultations endpoints. */
	public readonly consultations = {
		base: this.toApi('consultations/'),
		entity: (id: Session['id']) => this.toApi('consultations', `${id}/`),
		rates: (id: number) => this.toApi('consultations/rates', `${id}`),
		callCredentials: (id: number) => this.toApi(`consultations/${id}/get-session-token/`),
		checkout: (id: number) => this.toApi(`consultations/${id}/checkout/`),
		chat: (id: Session['id']) => this.toApi(`consultations/${id}/chat/`),
		rate: (id: number) => this.toApi(`consultations/${id}/rating/`),
	} as const;

	/** URLs for videos endpoints. */
	public readonly videos = {
		auth: this.toApi('videos/auth/'),
	} as const;

	/** URLs for payments endpoints. */
	public readonly payments = {
		attachPayment: this.toApi('payments/attach-payment/'),
		checkoutSession: this.toApi('payments/checkout-session/'),
		stripeAccountStatus: this.toApi('payments/stripe-account-status/'),
		savedPaymentMethods: this.toApi('payments/saved-payment-methods/'),
		removePaymentMethod: this.toApi('payments/remove-payment-method/'),
		embeddedComponentsSecret: this.toApi('payments/embedded-component-session/'),
	} as const;

	/** URLs for educations endpoints. */
	public readonly educations = {
		base: this.toApi('educations/'),
		entity: (id: Education['id']) => this.toApi('educations', `${id}/`),
	} as const;

	/** URLs for trainings endpoints. */
	public readonly trainings = {
		base: this.toApi('trainings/'),
		entity: (id: Training['id']) => this.toApi('trainings', `${id}/`),
	} as const;

	/** URLs for documents endpoints. */
	public readonly documents = {
		base: this.toApi('documents/'),
		entity: (id: Credential['id']) => this.toApi('documents', `${id}/`),
	} as const;

	/** URLs for chats endpoints. */
	public readonly chat = {
		base: this.toApi('chats/'),
		auth: this.toApi('chats/auth'),
	} as const;

	/** URLs for gifts endpoints. */
	public readonly gift = {
		send: this.toApi('payments/gift-send/'),
		receive: this.toApi('payments/gift-receive/'),
		receiveDetails: (id: number) => this.toApi(`payments/gift-receive/${id}/`),
		redeem: (id: number) => this.toApi(`payments/gift-receive/${id}/redeem/`),
		entity: (id: string | number) => this.toApi(`payments/gift-send/${id}/`),
		giftTypes: this.toApi('payments/gifts/'),
		payment: (id: string | number) => this.toApi(`payments/gift-send/${id}/checkout/`),
	} as const;

	/** URLs for notifications endpoint. */
	public readonly notifications = {
		base: this.toApi('notifications/'),
		markAsRead: this.toApi('notifications/mark-as-read/'),
		markAsUnread: this.toApi('notifications/mark-as-unread/'),
		unreadCount: this.toApi('notifications/unread-count/'),
		entity: (id: Notification['id']) => this.toApi('notifications', `${id}/`),
	} as const;

	/** URLs for validations endpoint. */
	public readonly validations = {
		verifyEmail: this.toApi('users/verify-email/'),
		verifyUsername: this.toApi('users/verify-username/'),
		verifyNpiNumber: this.toApi('users/verify-npi-number/'),
		verifySignature: this.toApi('users/verify-signature/'),
	};

	/** URLs for pages endpoints. */
	public readonly pages = {
		base: this.toApi('pages/'),
		entity: (id: string | number) => this.toApi(`pages/${id}/`),
		incomingPages: this.toApi('pages/received/'),
		acceptIncomingPage: (id: string | number) => this.toApi(`pages/received/${id}/accept/`),
		declineIncomingPage: (id: string | number) => this.toApi(`pages/received/${id}/decline/`),
		incomingPageDetails: (id: string | number) => this.toApi(`pages/received/${id}/`),
		sentPages: this.toApi('pages/sent/'),
		sendPage: (id: string | number) => this.toApi(`pages/${id}/send/`),
		checkout: (id: string | number) => this.toApi(`pages/${id}/checkout/`),
		selectConsultant: (id: string | number) => this.toApi(`pages/${id}/select-consultant/`),
	};

	/**
	 * Checks whether the url is application-scoped.
	 * @param url Url to check.
	 */
	public isApplicationUrl(url: string): boolean {
		return url.startsWith(this.appConfigService.apiUrl);
	}

	/**
	 * Checks whether the specified url is calling an auth-related endpoint.
	 * @param url Url to check.
	 */
	public isAuthUrl(url: string): boolean {
		return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
	}

	private toApi(...args: readonly string[]): string {
		const path = args.join('/');
		return new URL(path, this.appConfigService.apiUrl).toString();
	}
}
