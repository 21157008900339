import { z } from 'zod';

import { educationFileCreateUpdateDtoSchema, educationFileDtoSchema } from './education-file.dto';

/** Education DTO schema. */
export const educationDtoSchema = z.object({

	/** ID. */
	id: z.number(),

	/** Education program. */
	program: z.string(),

	/** School. */
	school: z.string(),

	/** City. */
	city: z.string(),

	/** State. */
	state: z.string(),

	/**
	 * Date of completion.
	 * @example 2024-12-31
	 */
	date_of_completion: z.string(),

	/** Education major. */
	major: z.string(),

	/** Degree certificate earned. */
	degree_certificate_earned: z.string(),

	/** Files. */
	files: z.array(educationFileDtoSchema),
});

/** Education DTO. */
export type EducationDto = z.infer<typeof educationDtoSchema>;

/** Education create/update DTO schema. */
export const educationCreateUpdateDtoSchema = educationDtoSchema.pick({
	program: true,
	school: true,
	city: true,
	state: true,
	date_of_completion: true,
	major: true,
	degree_certificate_earned: true,
}).extend({
	files: z.array(educationFileCreateUpdateDtoSchema),
});

/** Education create/update DTO. */
export type EducationCreateUpdateDto = Readonly<z.infer<typeof educationCreateUpdateDtoSchema>>;
