import { z } from 'zod';

import { credentialTypeSchema } from '../../models/credentials/credential-type';

/** Credential DTO schema. */
export const credentialDtoSchema = z.object({

	/** ID. */
	id: z.number(),

	/** Type. */
	type: credentialTypeSchema,

	/** Issued on date. */
	issued_on: z.string().nullish(),

	/** Expires on date. */
	expires_on: z.string().nullish(),

	/** Status. */
	status: z.string().nullish(),

	/** Certification number. */
	certification_number: z.string().nullish(),

	/** License number. */
	license_number: z.string().nullish(),

	/** Registration number. */
	registration_number: z.string().nullish(),

	/** Policy number. */
	policy_number: z.string().nullish(),

	/** Certification board. */
	certification_board: z.string().nullish(),

	/** State. */
	state: z.string().nullish(),

	/** File URL. */
	file: z
		.string()
		.min(1)
		.nullable(),

	/** Insurance carrier. */
	insurance_carrier: z.string().nullish(),

	/** Coverage. */
	coverage: z.string(),

	/** Effective date. */
	effective_date: z.string().nullish(),
});

/** Update credential DTO schema. */
export const updateCredentialDtoSchema = credentialDtoSchema.omit({ id: true });

/** Credential DTO. */
export type CredentialDto = Readonly<z.infer<typeof credentialDtoSchema>>;

/** Credential create DTO. */
export type CredentialCreateDto = Readonly<z.infer<typeof updateCredentialDtoSchema>>;
