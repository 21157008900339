import { z } from 'zod';

import { AppUtils } from './utils';

export namespace DateUtils {

	/**
		* Format data to Date only string format.
		* @param date Date.
		* @param format Format of date.
		*/
	export function toDateOnlyString(date: Date, format: DateOnlyFormat = DateOnlyFormat.First): string {
		const dateCandidate = z.date().safeParse(date);
		if (dateCandidate.success) {
			const _date = dateCandidate.data;
			const year = _date.getFullYear().toString();
			const month = AppUtils.zeroPad(_date.getMonth() + 1, 2);
			const day = AppUtils.zeroPad(_date.getDate(), 2);

			if (format === DateOnlyFormat.First) {
				return [year, month, day].join('-');
			}
		}
		return '';
	}

	/**
	 * Check if date is today.
	 * @param date Date to check.
	 */
	export function isToday(date: Date): boolean {
		const today = new Date();
		return datesEqual(date, today);
	}

	/**
	 * Check if date is yesterday.
	 * @param date Date to check.
	 */
	export function isYesterday(date: Date): boolean {
		const yesterday = new Date();
		yesterday.setDate(yesterday.getDate() - 1);
		return datesEqual(date, yesterday);
	}

	/**
	 * Check if dates are equal by year, month and date.
	 * Does not work with time.
	 * @param date1 First date.
	 * @param date2 Second date.
	 */
	export function datesEqual(date1: Date, date2: Date): boolean {
		return date1.getDate() === date2.getDate() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getFullYear() === date2.getFullYear();
	}

	/**
	 * Compare two dates.
	 * @param date One date.
	 * @param anotherDate Another date.
	 * @returns 1 is date is smaller then anotherDate, -1 if date is bigger then anotherDate, 0 if dates are equal.
	 */
	export function compare(date: Date, anotherDate: Date): number {
		const dateTime = date.getTime();
		const anotherDateTime = anotherDate.getTime();

		if (dateTime < anotherDateTime) {
			return 1;
		}

		if (dateTime > anotherDateTime) {
			return -1;
		}

		return 0;
	}

	/** Date only format. */
	export enum DateOnlyFormat {

		/** YYYY-MM-DD format. */
		First = 'yyyy-mm-dd',
	}
}
